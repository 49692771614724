<template>
  <TopNav></TopNav>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: 'Iletisim',
  components: {
    TopNav
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

footer section a {
  margin: 20px;
}
</style>
